import React, { useState } from "react";
import Iconify from "@/Components/iconify/Iconify";
import {
    Box,
    Button,
    Container,
    Grid,
    Paper,
    Stack,
    Typography,
    TextField,
    styled,
} from "@mui/material";
import Image from "@/Components/image/Image";
import PropTypes from "prop-types";
import { Link } from "@inertiajs/react";
import Faqs from "./Faqs";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import { motion } from "framer-motion";

const SectionHero = styled("section")`
    width: 90px;
    height: 90vh;
    background: url("/assets/images/hero-bg.png") top center;
    background-size: cover;
    margin-bottom: -200px;
    display: flex;
    align-items: center;
`;
const AboutUs = styled(Stack)`
    margin-top: 40px !important;
    padding: 0 !important;
    & .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        border: 2px solid #8dc2f1;
        border-radius: 50px;
        transition: 0.5s;
    }
`;
const CountBox = styled("section")`
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
    overflow: visible;
    & .count-icon {
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        background: #1977cc;
        color: #fff;
        border-radius: 50px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
    }

    & .icon-text {
        padding: 0;
        margin: 0;
        font-family: "Raleway", sans-serif;
        font-size: 14px;
    }
`;
const ServicesBox = styled("section")`
    text-align: center;
    border: 1px solid #d5e1ed;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;

    &:hover {
        background: #1977cc;
        border-color: #1977cc;

        & .icon {
            background: #fff;

            & .icon-services {
                color: #1977cc;
            }
        }

        & .services-head {
            color: #fff;
        }

        & .services-content {
            color: #fff;
        }

        & .icon {
            background: #fff;

            & .icon-services {
                color: #1977cc;
            }
        }
    }

    & .icon {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        background: #1977cc;
        border-radius: 5px;
        transition: all 0.3s ease-out 0s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        transform-style: preserve-3d;

        & .icon-services {
            color: #fff;
            font-size: 28px !important;
        }
    }

    & .icon::before {
        position: absolute;
        content: "";
        left: -8px;
        top: -8px;
        height: 100%;
        width: 100%;
        background: #badaf7;
        border-radius: 5px;
        transition: all 0.3s ease-out 0s;
        transform: translateZ(-1px);
    }
`;
const SectionTitle = styled(Typography)`
    color: #2c4964;
    position: relative;
    &::before {
        content: "";
        position: absolute;
        display: block;
        width: 120px;
        height: 1px;
        background: #ddd;
        bottom: 1px;
        left: calc(50% - 60px);
    }
    &::after {
        content: "";
        position: absolute;
        display: block;
        width: 40px;
        height: 3px;
        background: #1977cc;
        bottom: 0;
        left: calc(50% - 20px);
    }
`;

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function Content() {
    const [startCount, setStartCount] = useState(false);
    return (
        <main>
            <SectionHero id="hero">
                <Container maxWidth="xl">
                    <motion.div
                        initial={{ opacity: 0, x: -130 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.7 }}
                    >
                        <Typography variant="h1">
                            WELCOME TO UP
                            <span style={{ color: "red" }}>CARE</span>
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                    >
                        <Typography
                            variant="h4"
                            component="h2"
                            sx={{ fontWeight: "normal" }}
                        >
                            The Best Way to Care!
                        </Typography>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <Button
                            component={Link}
                            href={route("login")}
                            variant="contained"
                            sx={{
                                borderRadius: 99,
                                mt: 3,
                                px: 5,
                                textTransform: "uppercase",
                            }}
                            size="large"
                        >
                            Get Started
                        </Button>
                    </motion.div>
                </Container>
            </SectionHero>

            <Stack id="main">
                <Container maxWidth="xl" sx={{ py: 10, overflow: "hidden" }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} lg={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={12}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            bgcolor: "primary.main",
                                            color: "#fff",
                                            padding: 5,
                                        }}
                                    >
                                        <Typography variant="h3" mb={5}>
                                            Our Vision
                                        </Typography>
                                        <Typography variant="body1">
                                            At Upcareph, we believe in Filipino
                                            professionals' unyielding potential
                                            and dedication. Our platform is
                                            designed to showcase their talent
                                            and connect them seamlessly with
                                            employers and organizations that
                                            value their expertise.
                                        </Typography>
                                        <Box sx={{ mt: 5 }} />
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            py: 5,
                                            px: 4,
                                            textAlign: "center",
                                            height: 1,
                                        }}
                                    >
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            mb={4}
                                        >
                                            <Image
                                                src="/assets/images/landingicons/icon 3.png"
                                                sx={{
                                                    height: 120,
                                                    width: 120,
                                                    borderRadius: 50,
                                                }}
                                            />
                                        </Stack>
                                        <Typography
                                            variant="h5"
                                            sx={{ color: "#444444", mb: 4 }}
                                        >
                                            Why We're Unique
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#848484" }}
                                        >
                                            Every professional can curate a
                                            distinct profile, encapsulating
                                            their experiences, skills, and
                                            aspirations.
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            py: 5,
                                            px: 4,
                                            textAlign: "center",
                                            height: 1,
                                        }}
                                    >
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            mb={4}
                                        >
                                            <Image
                                                src="/assets/images/landingicons/icon 2.png"
                                                sx={{
                                                    height: 120,
                                                    width: 120,
                                                    borderRadius: 50,
                                                }}
                                            />
                                        </Stack>
                                        <Typography
                                            variant="h5"
                                            sx={{ color: "#444444", mb: 4 }}
                                        >
                                            Professionals
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#848484" }}
                                        >
                                            Showcase Your Excellence Build Your
                                            Profile: Our intuitive platform lets
                                            you illustrate your journey, skills,
                                            and aspirations effortlessly.
                                        </Typography>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            py: 5,
                                            px: 4,
                                            textAlign: "center",
                                            height: 1,
                                        }}
                                    >
                                        <Stack
                                            alignItems="center"
                                            justifyContent="center"
                                            mb={4}
                                        >
                                            <Image
                                                src="/assets/images/landingicons/icon 1.png"
                                                sx={{
                                                    height: 120,
                                                    width: 120,
                                                    borderRadius: 50,
                                                }}
                                            />
                                        </Stack>
                                        <Typography
                                            variant="h5"
                                            sx={{ color: "#444444", mb: 4 }}
                                        >
                                            Employers
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            sx={{ color: "#848484" }}
                                        >
                                            Discover Filipino Brilliance Diverse
                                            Talent Pool: Dive into a reservoir
                                            of talented professionals across
                                            myriad domains.
                                        </Typography>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Stack>
            {/* About US */}
            <Box id="about" sx={{ backgroundColor: "#fff" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item lg={6}>
                        <Image src="/assets/images/landingicons/icon 4.png" />
                    </Grid>
                    <Grid item lg={6}>
                        <Grid item lg={12} sx={{ padding: "24px" }}>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontFamily: "Raleway, sans-serif",
                                    paddin: 0,
                                    margin: 0,
                                }}
                            >
                                Why Choose UpCare?
                            </Typography>
                            <Typography sx={{ marginTop: "12px" }}>
                                Discover, connect, and thrive. Upcareph bridges
                                the gap between Filipino professionals and
                                global employers in search of excellence.
                            </Typography>
                            <AboutUs spacing={10}>
                                <Grid item lg={12}>
                                    <Grid
                                        container
                                        className="icon-box"
                                        spacing={2}
                                    >
                                        <Grid item lg={2} md={2} xs={2}>
                                            <Image
                                                src="/assets/images/landingicons/icon 5.png"
                                                sx={{
                                                    height: 60,
                                                    width: 60,
                                                    borderRadius: 50,
                                                    margin: "0 auto",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={10} md={10} xs={10}>
                                            <Box className="icon-box">
                                                <Typography variant="h6">
                                                    Professional Development
                                                </Typography>
                                                <Typography>
                                                    Gain access to continuous
                                                    learning and development
                                                    opportunities. We support
                                                    your growth in the medical
                                                    field.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={2} md={2} xs={2}>
                                            <Image
                                                src="/assets/images/landingicons/icon 6.png"
                                                sx={{
                                                    height: 60,
                                                    width: 60,
                                                    borderRadius: 50,
                                                    margin: "0 auto",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={10} md={10} xs={10}>
                                            <Box className="icon-box">
                                                <Typography variant="h6">
                                                    Work-Life Balance
                                                </Typography>
                                                <Typography>
                                                    We value your well-being.
                                                    Enjoy flexible work
                                                    schedules and ample vacation
                                                    days to maintain a healthy
                                                    work-life balance.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item lg={12}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={2} md={2} xs={2}>
                                            <Image
                                                src="/assets/images/landingicons/icon 7.png"
                                                sx={{
                                                    height: 60,
                                                    width: 60,
                                                    borderRadius: 50,
                                                    margin: "0 auto",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item lg={10} md={10} xs={10}>
                                            <Box className="icon-box">
                                                <Typography variant="h6">
                                                    Career Advancement
                                                </Typography>
                                                <Typography>
                                                    We encourage internal growth
                                                    and provide opportunities
                                                    for career advancement,
                                                    whether it's climbing the
                                                    ranks or specializing in a
                                                    specific medical area.
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AboutUs>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* END */}

            {/* Counts US */}
            <Box
                sx={{
                    paddingTop: "60px",
                    paddingBottom: "60px",
                    backgroundColor: "#f1f7fd",
                }}
            >
                <Container maxWidth="xl">
                    <InView
                        triggerOnce={true}
                        onChange={(inView) => setStartCount(inView)}
                    >
                        <Grid container spacing={2} alignItems="center">
                            <Grid item lg={3} md={6} xs={12}>
                                <CountBox>
                                    <Box className="count-icon">
                                        <Iconify icon="mdi:partnership" />
                                    </Box>
                                    <Box
                                        data-purecounter-start="0"
                                        data-purecounter-end="85"
                                        data-purecounter-duration="1"
                                        className="purecounter"
                                    ></Box>
                                    <Typography
                                        variant="h4"
                                        sx={{ mt: 2, fontWeight: 600 }}
                                    >
                                        {startCount ? (
                                            <CountUp end={1000} duration={2} />
                                        ) : (
                                            "0"
                                        )}
                                        <Box
                                            component={"span"}
                                            sx={{ fontWeight: 300 }}
                                        >
                                            +
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6">Nurses</Typography>
                                </CountBox>
                            </Grid>
                            <Grid item lg={3} md={6} xs={12}>
                                <CountBox>
                                    <Box className="count-icon">
                                        <Iconify icon="la:users" />
                                    </Box>
                                    <Box
                                        data-purecounter-start="0"
                                        data-purecounter-end="85"
                                        data-purecounter-duration="1"
                                        className="purecounter"
                                    ></Box>
                                    <Typography
                                        variant="h4"
                                        sx={{ mt: 2, fontWeight: 600 }}
                                    >
                                        {startCount ? (
                                            <CountUp end={500} duration={2} />
                                        ) : (
                                            "0"
                                        )}

                                        <Box
                                            component={"span"}
                                            sx={{ fontWeight: 300 }}
                                        >
                                            +
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6">
                                        Organizations
                                    </Typography>
                                </CountBox>
                            </Grid>
                            <Grid item lg={3} md={6} xs={12}>
                                <CountBox>
                                    <Box className="count-icon">
                                        <Iconify icon="ion:briefcase" />
                                    </Box>
                                    <Box
                                        data-purecounter-start="0"
                                        data-purecounter-end="85"
                                        data-purecounter-duration="1"
                                        className="purecounter"
                                    ></Box>
                                    <Typography
                                        variant="h4"
                                        sx={{ mt: 2, fontWeight: 600 }}
                                    >
                                        {startCount ? (
                                            <CountUp end={1000} duration={2} />
                                        ) : (
                                            "0"
                                        )}
                                        <Box
                                            component={"span"}
                                            sx={{ fontWeight: 300 }}
                                        >
                                            +
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6">
                                        Job Opportunities
                                    </Typography>
                                </CountBox>
                            </Grid>
                            <Grid item lg={3} md={6} xs={12}>
                                <CountBox>
                                    <Box className="count-icon">
                                        <Iconify icon="grommet-icons:user-manager" />
                                    </Box>
                                    <Box
                                        data-purecounter-start="0"
                                        data-purecounter-end="85"
                                        data-purecounter-duration="1"
                                        className="purecounter"
                                    ></Box>
                                    <Typography
                                        variant="h4"
                                        sx={{ mt: 2, fontWeight: 600 }}
                                    >
                                        {startCount ? (
                                            <CountUp end={500} duration={2} />
                                        ) : (
                                            "0"
                                        )}
                                        <Box
                                            component={"span"}
                                            sx={{ fontWeight: 300 }}
                                        >
                                            +
                                        </Box>
                                    </Typography>
                                    <Typography variant="h6">
                                        Employers
                                    </Typography>
                                </CountBox>
                            </Grid>
                        </Grid>
                    </InView>
                </Container>
            </Box>
            {/* END */}

            {/* Services */}
            <Box
                id="services"
                sx={{
                    paddingTop: "60px",
                    paddingBottom: "60px",
                    backgroundColor: "#fff",
                }}
            >
                <Container maxWidth="xl">
                    <Box className="section-title">
                        <Typography variant="h2">
                            UpCare-The best way to care!
                        </Typography>
                        <Typography variant="p">
                            Filipinos approach their work with unparalleled
                            dedication and passion, often going above and beyond
                            to ensure excellence in their roles. <br />
                            Embedded in the Filipino culture is a deep sense of
                            responsibility and care for their jobs, reflecting a
                            commitment not only to their tasks but to the
                            broader community they serve.
                        </Typography>
                    </Box>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item lg={4} xs={12}>
                            <ServicesBox className="icon-box">
                                <Stack sx={{ pb: 2 }}>
                                    <Image
                                        src="/assets/images/landingicons/icon 8.png"
                                        sx={{
                                            height: 200,
                                            width: 300,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                </Stack>
                                <Typography
                                    variant="h4"
                                    className="services-head"
                                >
                                    Build your Profile
                                </Typography>
                                <Typography
                                    variant="p"
                                    className="services-content"
                                >
                                    Craft a comprehensive digital representation
                                    of your skills, experiences, and
                                    accomplishments. This initial step lays the
                                    foundation for potential employers to
                                    understand your capabilities.
                                </Typography>
                            </ServicesBox>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <ServicesBox className="icon-box">
                                <Stack sx={{ pb: 2 }}>
                                    <Image
                                        src="/assets/images/landingicons/icon 9.png"
                                        sx={{
                                            height: 200,
                                            width: 200,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                </Stack>
                                <Typography
                                    variant="h4"
                                    className="services-head"
                                >
                                    Connect
                                </Typography>
                                <Typography
                                    variant="p"
                                    className="services-content"
                                >
                                    Engage with the platform's community,
                                    reaching out to potential employers,
                                    colleagues, and industry leaders. By
                                    networking actively, you unlock doors to
                                    numerous opportunities.
                                </Typography>
                            </ServicesBox>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <ServicesBox className="icon-box">
                                <Stack sx={{ pb: 2 }}>
                                    <Image
                                        src="/assets/images/landingicons/icon 11.png"
                                        sx={{
                                            height: 200,
                                            width: 300,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                </Stack>
                                <Typography
                                    variant="h4"
                                    className="services-head"
                                >
                                    Get Matched
                                </Typography>
                                <Typography
                                    variant="p"
                                    className="services-content"
                                >
                                    Let our advanced algorithms pair you with
                                    job opportunities that align with your
                                    skills and preferences.
                                    <br /> This ensures that both professionals
                                    and employers find the right fit.
                                </Typography>
                            </ServicesBox>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <ServicesBox className="icon-box">
                                <Stack sx={{ pb: 2 }}>
                                    <Image
                                        src="/assets/images/landingicons/icon 13.png"
                                        sx={{
                                            height: 200,
                                            width: 300,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                </Stack>
                                <Typography
                                    variant="h4"
                                    className="services-head"
                                >
                                    Qualified
                                </Typography>
                                <Typography
                                    variant="p"
                                    className="services-content"
                                >
                                    After getting matched, undergo a vetting
                                    <br /> process where your skills and
                                    experiences are further evaluated,
                                    <br /> ensuring you're the perfect fit for
                                    the role.
                                </Typography>
                            </ServicesBox>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <ServicesBox className="icon-box">
                                <Stack sx={{ pb: 2 }}>
                                    <Image
                                        src="/assets/images/landingicons/icon 12.png"
                                        sx={{
                                            height: 200,
                                            width: 300,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                </Stack>
                                <Typography
                                    variant="h4"
                                    className="services-head"
                                >
                                    Work as Team
                                </Typography>
                                <Typography
                                    variant="p"
                                    className="services-content"
                                >
                                    Collaborate effectively with your new
                                    colleagues <br />
                                    and integrate into the organization's work
                                    culture. <br />
                                    Embrace teamwork
                                    <br /> to achieve shared objectives.
                                </Typography>
                            </ServicesBox>
                        </Grid>
                        <Grid item lg={4} xs={12}>
                            <ServicesBox className="icon-box">
                                <Stack sx={{ pb: 2 }}>
                                    <Image
                                        src="/assets/images/landingicons/icon 10.png"
                                        sx={{
                                            height: 200,
                                            width: 300,
                                            objectFit: "cover",
                                            margin: "0 auto",
                                        }}
                                    />
                                </Stack>
                                <Typography
                                    variant="h4"
                                    className="services-head"
                                >
                                    Goals
                                </Typography>
                                <Typography
                                    variant="p"
                                    className="services-content"
                                >
                                    Set, pursue, and achieve professional
                                    milestones. <br />
                                    This step is a continuous journey of growth
                                    and progression,
                                    <br /> ensuring you
                                    <br /> and the organization move forward
                                    together.
                                </Typography>
                            </ServicesBox>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {/* END */}

            {/* Departments */}
            {/* <Box id="departments" sx={{ paddingTop: '60px', paddingBottom: '60px', backgroundColor: '#f1f7fd' }}>
				<Container maxWidth='xl'>
					<Box className="section-title">
						<Typography variant='h2'>Departments</Typography>
						<Typography variant='p'>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
							consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
							fugiat sit in iste officiis commodi quidem hic quas.</Typography>
					</Box>
					<Grid container spacing={2} alignItems={'center'}>
						<Grid item lg={3} xs={12}>
							<Tabs value={value} onChange={handleChange} orientation="vertical" aria-label="basic tabs example">
								<Tab label="Cardiology" {...a11yProps(0)} sx={{ width: '100% !important', justifyContent: 'left' }} />
								<Tab label="Neurology" {...a11yProps(1)} sx={{ width: '100% !important', justifyContent: 'left' }} />
								<Tab label="Hepatology" {...a11yProps(2)} sx={{ width: '100% !important', justifyContent: 'left' }} />
								<Tab label="Pediatrics" {...a11yProps(3)} sx={{ width: '100% !important', justifyContent: 'left' }} />
								<Tab label="Eye Care" {...a11yProps(4)} sx={{ width: '100% !important', justifyContent: 'left' }} />
							</Tabs>
						</Grid>
						<Grid item lg={9} xs={12}>
							<CustomTabPanel value={value} index={0} >
								<Stack sx={{ height: '250px !important' }}>
									<Typography variant='h6' sx={{ marginBottom: '1rem' }}>
										Cardiology
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni. Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero
									</Typography>
								</Stack>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={1}>
								<Stack sx={{ height: '250px !important' }}>
									<Typography variant='h6' sx={{ marginBottom: '1rem' }}>
										Neurology
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni. Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero
									</Typography>
								</Stack>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={2}>
								<Stack sx={{ height: '250px !important' }}>
									<Typography variant='h6' sx={{ marginBottom: '1rem' }}>
										Hepatology
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni. Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero
									</Typography>
								</Stack>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={3}>
								<Stack sx={{ height: '250px !important' }}>
									<Typography variant='h6' sx={{ marginBottom: '1rem' }}>
										Pediatrics
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni. Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero
									</Typography>
								</Stack>
							</CustomTabPanel>
							<CustomTabPanel value={value} index={4}>
								<Stack sx={{ height: '250px !important' }}>
									<Typography variant='h6' sx={{ marginBottom: '1rem' }}>
										Eye Care
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Qui laudantium consequatur laborum sit qui ad sapiente dila parde sonata raqer a videna mareta paulona marka
									</Typography>
									<Typography variant='p' fontStyle="italic" sx={{ marginBottom: '1rem', color: '#80868d' }}>
										Et nobis maiores eius. Voluptatibus ut enim blanditiis atque harum sint. Laborum eos ipsum ipsa odit magni. Incidunt hic ut molestiae aut qui. Est repellat minima eveniet eius et quis magni nihil. Consequatur dolorem quaerat quos qui similique accusamus nostrum rem vero
									</Typography>
								</Stack>
							</CustomTabPanel>

						</Grid>
					</Grid>
				</Container>

			</Box> */}
            {/* END */}

            {/* Frequently Asked Questions */}
            <Box
                sx={{
                    paddingTop: "60px",
                    paddingBottom: "60px",
                    backgroundColor: "#f1f7fd",
                }}
            >
                <Faqs />
            </Box>
            {/* END */}

            {/* Organizations */}
            <Box id="about" sx={{ backgroundColor: "#fff" }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item lg={5} md={6} sm={12}>
                        <Image
                            src="/assets/images/sir.png"
                            sx={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item lg={7} md={6} sm={12}>
                        <Grid item lg={12} sx={{ padding: "24px" }}>
                            <Grid item lg={12} sx={{ padding: "24px" }}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        fontFamily: "Raleway, sans-serif",
                                        paddin: 0,
                                        margin: 0,
                                        color: "#001c4e",
                                    }}
                                >
                                    PARTNER WITH US
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ marginTop: "12px", lineHeight: "2" }}
                                >
                                    In the heart of the Philippines lies a
                                    wealth of talent, dedication, and
                                    resilience. We, at UpCare, recognize the
                                    boundless potential of our Filipino
                                    professionals, and it has been our
                                    unwavering mission to be the bridge that
                                    connects this immense talent pool with
                                    global opportunities. <br />
                                    <br />
                                    Our homeland has always been a tapestry of
                                    hardworking individuals, passionate about
                                    their craft and committed to their roles. It
                                    is this essence that we want the world to
                                    see, value, and embrace. UpCare is not just
                                    a platform; it's a movement. A movement to
                                    uplift, celebrate, and champion the
                                    capabilities of our countrymen and women.
                                    <br />
                                    <br />
                                    Every connection made on UpCare signifies a
                                    step forward in showcasing the expertise and
                                    dedication inherent in Filipino
                                    professionals. We are not merely linking
                                    employees to employers but are fostering
                                    relationships, building collaborations, and
                                    co-creating futures.
                                    <br />
                                    <br />
                                    In this journey, I am deeply grateful for
                                    the trust and partnership of every
                                    professional, employer, and organization
                                    that believes in our vision. Together, we
                                    are making a difference; together, we are
                                    elevating Filipino talent on the global
                                    stage.
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{
                                        marginTop: "40px",
                                        lineHeight: "1.5",
                                    }}
                                >
                                    Chris
                                </Typography>
                                <Typography variant="body2">
                                    Founder, Upcareph
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            {/* END */}

            {/* Testimonials */}
            {/* <Box sx={{ paddingTop: '60px', paddingBottom: '60px', backgroundColor: '#fff' }}>
				<Box className="section-title">
					<Typography variant='h2'>Testimonials</Typography>
				</Box>
				<Container>
					<TestimonialWrap>
						<Box component='div' className='testimonial-item'>
							<Image
								src="/assets/images/about.jpg"
								sx={{ width: '90px' }}
								className="testimonial-img"
							/>
							<Typography variant='h3'>Emily Smith</Typography>
							<Typography variant='h4'>New Grad RN</Typography>
							<Typography variant='p' sx={{
								fontStyle: 'italic'
							}}><Iconify icon='mingcute:quote-left-fill' sx={{ fontSize: '26px', color: '#badaf7' }} />I've been a nurse for years, but UpCare still impresses me. The continuous job updates and personalized job recommendations have kept my career on the right track. It's my go-to platform for all things nursing.<Iconify icon='mingcute:quote-right-fill' sx={{ width: '20px', height: '20px', color: '#badaf7' }} /> </Typography>
						</Box>
					</TestimonialWrap>
				</Container>
			</Box> */}
            {/* END */}

            {/* Gallery */}
            {/* <Box sx={{ paddingTop: '60px', paddingBottom: '60px', backgroundColor: '#fff' }}>
				<Container maxWidth='xl'>
					<Box className="section-title">
						<Typography variant='h2'>Gallery</Typography>
						<Typography variant='p'>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint
							consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia
							fugiat sit in iste officiis commodi quidem hic quas.</Typography>
					</Box>
				</Container>
				<Grid container spacing={1} sx={{ padding: 1 }}>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
					<Grid item lg={3}>
						<Image
							src="/assets/images/about.jpg"
							className="testimonial-img"
						/>
					</Grid>
				</Grid>
			</Box> */}
            {/* END */}

            {/* CONTACT SECTION */}
            <Stack id="contact" sx={{ py: 7 }}>
                <Container sx={{ mb: 4 }}>
                    <Stack textAlign="center">
                        <SectionTitle variant="h3" sx={{ mb: 3, pb: 2 }}>
                            Get in Touch with Us!
                        </SectionTitle>
                    </Stack>
                </Container>
                <iframe
                    style={{ border: "0", width: "100%", height: "350px" }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d770.6507322506691!2d125.61390932828941!3d7.086516644348656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f96da8962857d9%3A0xb22a587f6e0874dc!2sPinoyCare%20Business%20Consultancy%20Services!5e0!3m2!1sen!2sph!4v1681782739373!5m2!1sen!2sph"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
                <Container sx={{ paddingTop: "60px", paddingBottom: "60px" }}>
                    <Grid container spacing={2}>
                        <Grid item lg={5} xs={12}>
                            <Stack spacing={3}>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Box sx={{ pt: 0.5 }}>
                                            <Iconify
                                                icon="tdesign:location"
                                                width={24}
                                            />
                                        </Box>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h4"
                                                sx={{ fontWeight: "normal" }}
                                            >
                                                Location:
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="primary.lighter"
                                            >
                                                Davao City, 8000 Davao del Sur,
                                                Philippines
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Box sx={{ pt: 0.5 }}>
                                            <Iconify
                                                icon="ic:outline-email"
                                                width={24}
                                            />
                                        </Box>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h4"
                                                sx={{ fontWeight: "normal" }}
                                            >
                                                Email:
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="primary.lighter"
                                            >
                                                join@upcareph.com
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <Box sx={{ pt: 0.5 }}>
                                            <Iconify
                                                icon="bi:phone"
                                                width={24}
                                            />
                                        </Box>
                                        <Stack spacing={2}>
                                            <Typography
                                                variant="h4"
                                                sx={{ fontWeight: "normal" }}
                                            >
                                                Call:
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                color="primary.lighter"
                                            >
                                                09092603898
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item lg={7} xs={12}>
                            <Stack spacing={3}>
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Your Name"
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        label="Your Email"
                                    />
                                </Stack>
                                <TextField
                                    fullWidth
                                    size="small"
                                    label="Subject"
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    multiline
                                    rows={5}
                                    label="Message"
                                />
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Button
                                        variant="contained"
                                        size="large"
                                        endIcon={
                                            <Iconify icon="carbon:send-filled" />
                                        }
                                    >
                                        Send Message
                                    </Button>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Stack>
            {/* END */}
        </main>
    );
}
