import Iconify from "@/Components/iconify/Iconify";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { faqs } from "@/utils/landingPageData";

function Faqs() {
    return (
        <>
            <Container maxWidth="xl">
                <Box className="section-title">
                    <Typography variant="h2">FAQ</Typography>
                </Box>
                <Stack sx={{ marginTop: 2 }} spacing={1.5}>
                    {faqs.map((faq, key) => (
                        <Accordion sx={{ backgroundColor: "#fff" }} key={key}>
                            <AccordionSummary
                                expandIcon={<Iconify icon="ooui:expand" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Box
                                    sx={{
                                        paddingTop: 0.2,
                                        paddingRight: 1,
                                    }}
                                >
                                    <Iconify
                                        icon="material-symbols:help-outline"
                                        sx={{
                                            color: "#1977cc",
                                            height: "24px",
                                            width: "24px",
                                            padding: 0,
                                            margin: 0,
                                        }}
                                    />
                                </Box>
                                <Typography
                                    variant="p"
                                    sx={{
                                        fontSize: "18px",
                                        color: "#1977cc",
                                    }}
                                >
                                    {faq.question}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ paddingTop: 0 }}>
                                {faq.answer}
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Stack>
            </Container>
        </>
    );
}

export default Faqs;
