import React, { useEffect } from "react";
import { Head, usePage, router } from "@inertiajs/react";
import Content from "@/sections/landingpage/Content";
import LandingLayout from "@/Layouts/LandingLayout";
import AuthenticatedLayout from "@/Layouts/user/AuthenticatedLayout";
import EmployerPortalLayout from "@/Layouts/employer/EmployerPortalLayout";
import AdminLayout from "@/Layouts/admin/AdminLayout";

export default function Homepage() {
    const {
        auth: { user },
    } = usePage().props;

    useEffect(() => {
        if (user?.role === "admin") {
            // console.log(user);
            // router.visit(route("admin.index"));
        }
    }, [user, router]);

    const getLayout = (user) => {
        if (!user) return LandingLayout;
        switch (user.role) {
            case "user":
                return AuthenticatedLayout;
            case "employer":
                return EmployerPortalLayout;
            case "admin":
                return AdminLayout; // Even if we redirect, this helps maintain a consistent pattern.
            default:
                return LandingLayout; // Fallback layout
        }
    };

    const Layout = getLayout(user);

    return (
        <Layout>
            <Head title="Home" />
            <Content />
        </Layout>
    );
}
