export const faqs = [
    {
        question: "Is Upcareph a staffing agency or recruitment agency?",
        answer: "Upcareph is not a staffing or recruitment agency. Instead, it serves as a smart platform that connects professionals with employers, organizations, and companies seeking outstanding individuals to join their teams. Upcareph aims to assist professionals in finding job opportunities globally.",
    },
    {
        question: "How do I post a job on UpCarePh?",
        answer: "To post a job, you must be an authorized representative of your organization or company. Clearly specify the details of the position and the type of candidate you are seeking. Job postings are typically approved within a maximum of 24 hours.",
    },
    {
        question: "What kind of jobs can I post on UpCarePh?",
        answer: "Currently, you can post job openings related to healthcare, business, and management.",
    },
    {
        question: "Is there a cost to post a job on UpCarePh?",
        answer: "Job posting is free for the first 100 companies and organizations.",
    },
    {
        question: "How long will my job posting stay active?",
        answer: "Your job posting will remain active for as long as you require.",
    },
    {
        question: "Can I edit my job posting after it goes live?",
        answer: "Yes, you can edit your job posting at any time after it goes live.",
    },
    {
        question: "How do I create a professional profile on UpCarePh?",
        answer: "Start by creating an account and then provide the necessary details to build your profile.",
    },
    {
        question: "What should I include in my UpCarePh profile?",
        answer: "To verify your account, include your identification and educational background.",
    },
    {
        question:
            "How can I improve my profile's visibility to potential employers?",
        answer: "Complete all the required information to enhance your profile's visibility.",
    },
    {
        question: "Is my personal information safe on UpCarePh?",
        answer: "Yes, all information on UpCarePh is secure and complies with Philippine laws and the Data Privacy Act.",
    },
    {
        question: "Can I receive job alerts based on my profile?",
        answer: "Yes, you will receive notifications through the mobile app and email based on your profile.",
    },
];
